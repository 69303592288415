@use "../../scss/variables.module.scss" as *;

.header {
  width: 100vw;
  height: auto;
  background-color: $unltd-red;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  height: 70px;
  // width: 200px;
  margin: 1rem;
  max-width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

button,
.link {
  color: $unltd-white;
  background-color: $unltd-red;
  border: 3px solid $unltd-gold;
  text-decoration: none;
  margin: 1rem;
  padding: 1em;
  font-weight: 600;
  font-size: 1em;
  cursor: pointer;
}

button:hover,
.link:hover {
  // color: $unltd-yellow;
  background-color: $unltd-gold;
}
