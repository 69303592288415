@use "../../scss/variables.module.scss" as *;

.logo-block {
  height: 70%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: $test-border;
}

.logo-block > svg {
  height: 100%;
  width: 100%;
}

.logo .text {
  fill: #ffffff;
}

.logo .blade {
  fill: #000000;
}

@media all and (min-width: 800px) {
  // .logo-block {
  //   width: 80px;
  //   height: 80px;
  // }
}
