$unltd-red: rgba(155, 8, 8, 1);
$unltd-red-highlight: rgba(250, 150, 150, 1);
$unltd-red-lowlight: rgba(110, 0, 0, 1);
$unltd-metallic-gold: rgba(149, 132, 98, 1);
$unltd-gold: rgb(160, 120, 70);
$unltd-charcoal: rgba(60, 60, 60, 1);
$unltd-black: rgba(10, 10, 10, 1);
$unltd-blue: rgba(10, 10, 130, 1);
$unltd-neon: rgba(245, 255, 0, 1);
$unltd-yellow: rgba(255, 255, 0, 1);
$unltd-black: rgba(10, 10, 10, 1);
$unltd-primary: rgba(180, 0, 0, 1);
$unltd-white: rgba(255, 255, 255, 1);
// $test-border: 1px solid pink;
$test-border: none;

$fullscreen-breakpoint: 1400px;
$screen-breakpoint: 1100px;
$nav-breakpoint: 770px;
$tablet-breakpoint: 600px;
$phone-breakpoint: 450px;

:export {
  phonesize: $phone-breakpoint;
  screensize: $screen-breakpoint;
  tabletsize: $tablet-breakpoint;
  navbreaksize: $nav-breakpoint;
  unltdRed: $unltd-red;
  unltdPrimary: $unltd-primary;
}
