@use "../../scss/variables.module.scss" as *;

.screenshot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.screenshot img {
  width: 90%;
  max-width: 600px;
  height: auto;
  border: 1px solid $unltd-charcoal;
  margin: 0.5rem 0rem 1rem 0rem;
}

.screenshot .small img {
  max-width: 400px;
}

.caption {
  font-weight: 700;
  font-size: 0.8em;
  text-transform: capitalize;
}

.alert {
  border: 2px dashed $unltd-red-highlight;
}
