@use "../../scss/variables.module.scss" as *;

.walkthrough {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.walkthrough p,
.walkthrough h1,
.walkthrough h2,
.walkthrough h3 {
  width: 100%;
}

.walkthrough p {
  margin: 0em 0em 2em 0em;
}

.walkthrough .callout {
  padding: 1em;
  background-color: beige;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.walkthrough h3 {
  color: $unltd-red;
  margin: 1em 0em 0.5em 0em;
}

.walkthrough button {
  padding: 1em;
  margin: 1em 1em 1em 0em;
  background-color: $unltd-red;
  color: $unltd-yellow;
  font-weight: 600;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.walkthrough button:hover {
  background-color: $unltd-red-highlight;
}

.alert {
  background-color: $unltd-red-highlight;
  border: 1px solid $unltd-red-lowlight;
  color: $unltd-red-lowlight;
  padding: 1em;
}
